@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

body {
  margin: 0;
  font-family: 'IBM Plex Mono', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../public/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* outline grid layout */

.app {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  height: 100vh;
  align-items: center;
}

.app-content {
  grid-column: 2;
  text-align: left;
}

/* webpage components */

h1 {
  font-weight: 900;
  color: #333333;
}

h1 .y {
  color: #FFA500;
}

h1 a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

ul li:before {
  content: "-";
  margin-right: 10px;
}

li a {
  font-weight: 900;
  color: #333333;
  text-decoration: none;
}

li a span {
  font-weight: normal;
}

.project {
  font-weight: 900;
  text-decoration: underline;
}

a {
  font-weight: 900;
  color: #333333;
}

/* specific to small screens */

@media (max-width: 1224px) {
  .app {
    grid-template-columns: 1fr 5fr 1fr;
  }
  
  ul {
    padding-left: 10px;
  }
}

.line {
  display: block;
}

@media only screen and (max-width: 780px) {
  .line {
    margin-bottom: 20px;
  }
}
